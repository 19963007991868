<template>
  <div class="flex gap-1 no-gutters items-baseline text-gray-900 mb-1">
    <span
      class="text leading-5"
      :class="{
        'font-bold text-lg':
          isBold == null ? !(isSmall || isStrikeThrough) : isBold,
        'text-sm md:text-lg': isResponsive,
        'text-sm text-gray-500': isSmall,
        'text-xs md:text-sm': isResponsive && isSmall,
        'line-through text-gray-500': isStrikeThrough
      }"
    >
      {{ formattedPrice }}
    </span>

    <span
      v-if="previousPrice"
      class="text-sm text-gray-500 line-through leading-3"
      :class="{ 'text-xs md:text-sm': isResponsive }"
    >
      {{ formatPrice(previousPrice) }}
    </span>

    <span
      v-if="label"
      class="block text-gray-500 text-sm leading-3"
      :class="{ 'text-xs md:text-sm': isResponsive }"
    >
      {{ label }}<slot name="label-append" />
    </span>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    price: {
      type: [String, Number],
      required: true
    },
    previousPrice: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    isStrikeThrough: {
      type: Boolean,
      required: false,
      default: false
    },
    isBold: {
      type: Boolean,
      required: false,
      default: null
    },
    isResponsive: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {
    const formatPrice = (price: string | number) => {
      if (price === 'N/A') return '£—'

      if (typeof price === 'string') {
        return price.includes('£') ? price : `£${price}`
      }

      return formatGBP(price)
    }

    const formattedPrice = computed(() => formatPrice(props.price))

    return { formattedPrice, formatPrice }
  }
}
</script>
